<template>
  <div class="myClass">
    <el-row class="btns">
      <el-button
        icon="el-icon-plus"
        class="btn creactBtn"
        @click="handleCreateClassVisible = true"
        >新建班级</el-button
      >
      <el-button
        type="info"
        class="btn"
        plain
        @click="handleAllocationClassVisible = true"
        >学生分配班级</el-button
      >
    </el-row>
    <el-row class="classList">
      <el-col
        class="classItem"
        v-for="item in classList"
        :key="item.shop_user_class_id"
        :xs="11"
        :sm="7"
        :md="7"
        :lg="7"
        :xl="5"
      >
        <el-row class="itemTop" type="flex" justify="space-between">
          <el-col :span="18" class="class_title">{{ item.class_title }}</el-col>
          <el-col :span="6">
            <el-row type="flex" justify="space-between" class="topIcon">
              <el-col class="iconOutline" @click.native="onEditClass(item)"
                ><i class="el-icon-edit-outline"></i
              ></el-col>
              <el-col
                class="iconDelete"
                @click.native="openDeleteConfirm(item.shop_user_class_id)"
                ><i class="el-icon-delete"></i
              ></el-col>
            </el-row>
          </el-col>
        </el-row>
        <div class="itemMainBox">
          <el-row class="itemMain" @click.native="onClassUserList(item)">
            <el-col class="mainImgBox" :span="12">
              <el-image
                class="mainImg"
                :src="require(`@/assets/image/adminClass.png`)"
              ></el-image>
            </el-col>
            <el-col class="mainNumBox" :span="12">
              <div class="mainNumTitle">班级学生</div>
              <div class="mainNum">{{ item.allot_count }}</div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <el-dialog title="新增班级" :visible.sync="handleCreateClassVisible"
      ><el-form :model="classForm">
        <el-form-item label="班级名称" :label-width="formLabelWidth">
          <el-input
            v-model="classForm.class_title"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCreateClassVisible = false">取 消</el-button>
        <el-button type="primary" @click="onCreateClassSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="学生分配班级" :visible.sync="handleAllocationClassVisible"
      ><el-form :model="selectClassForm">
        <el-form-item label="学生学号" :label-width="formLabelWidth">
          <el-input
            v-model="selectClassForm.phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择班级" :label-width="formLabelWidth"
          ><el-select
            v-model="selectClassForm.shop_user_class_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in classList"
              :key="item.shop_user_class_id"
              :label="item.class_title"
              :value="item.shop_user_class_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleAllocationClassVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="onAllocationClassSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      :title="classUserItem.class_title"
      :visible.sync="handleClassListVisible"
    >
      <el-row>
        <el-col
          :span="12"
          v-for="item in classUserList"
          :key="item.shop_user_class_allot_id"
        >
          <el-row>
            <el-col :span="10">{{ item.name }}</el-col>
            <el-col :span="10">{{ item.phone }}</el-col>
            <el-col
              class="deleteIcon"
              :span="4"
              @click.native="onDeleteClassUser(item.shop_user_class_allot_id)"
              ><i class="el-icon-delete"></i
            ></el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  creactClass,
  getAdminClassList,
  deleteClass,
  allotClass,
  getClassUser,
  delClassUser,
} from "@/request/api";
// import UploadModal from '../../../../components/UploadModal.vue'

export default {
  inject: ["reload"],
  // components: {
  //   UploadModal
  // },
  data() {
    return {
      classList: [], // 班级列表
      classUserList: [], // 班级列表
      classForm: {}, //班级表单对象
      selectClassForm: {}, //选择班级表单对象
      handleCreateClassVisible: false, // 新建班级模态框是否显示
      handleAllocationClassVisible: false, // 分配班级模态框是否显示
      handleClassListVisible: false, //班级学生列表
      formLabelWidth: "100px",
      classUserItem: {}, // 当前选择的班级
    };
  },
  methods: {
    // 删除班级学生确认
    onDeleteClassUser(shop_user_class_allot_id) {
      this.$confirm("此操作将删除该学生, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteClassUser(shop_user_class_allot_id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除班级学生
    async deleteClassUser(shop_user_class_allot_id) {
      const res = await delClassUser({ shop_user_class_allot_id, isNew: true });
      if (res.data.code === "1000") {
        this.$message.success("删除成功");
        this.onClassUserList(this.classUserItem);
      }
    },
    // 班级学生列表
    async onClassUserList(item) {
      const res = await getClassUser(item.shop_user_class_id);
      if (res.data.code === "1000") {
        this.classUserItem = item;
        this.classUserList = res.data.data;
        this.handleClassListVisible = true;
      }
    },
    // 新增班级提交表单事件
    onCreateClassSubmit() {
      const body = {
        isNew: true,
        class_title: this.classForm.class_title,
      };
      if (this.classForm.shop_user_class_id) {
        body.shop_user_class_id = this.classForm.shop_user_class_id;
      }
      creactClass(body).then((res) => {
        if (res.data.code === "1000") {
          if (body.shop_user_class_id) {
            this.$message.success("修改成功");
          } else {
            this.$message.success("添加成功");
          }
          this.init();
          this.handleCreateClassVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 编辑班级
    onEditClass(item) {
      this.classForm = { ...item };
      this.handleCreateClassVisible = true;
    },
    openDeleteConfirm(shop_user_class_id) {
      this.$confirm("此操作将删除该班级, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDeleteClass(shop_user_class_id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async onDeleteClass(shop_user_class_id) {
      const res = await deleteClass({ shop_user_class_id, isNew: true });
      if (res.data.code === "1000") {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.init();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    // 学生分配班级
    onAllocationClassSubmit() {
      allotClass({ ...this.selectClassForm, isNew: true }).then((res) => {
        if (res.data.code === "1000") {
          this.$message({
            type: "success",
            message: "分配成功",
          });
          this.init();
          this.handleAllocationClassVisible = false;
        } else {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
        }
      });
    },
    async init() {
      const res = await getAdminClassList();
      if (res.data.code === "1000") {
        this.classList = res.data.data;
      }
      this.classForm = {};
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.myClass {
  .deleteIcon {
    cursor: pointer;
  }
  .btns {
    margin: 48px 0px 72px 60px;
    .btn {
      width: 150px;
      // height: 40px;
      font-size: 20px;
      border-radius: 10px;
      padding: 9px 20px;
    }
    .creactBtn {
      color: #fff;
      background: #f5b400;
      border: 1px solid #f5b400;
      margin-right: 48px;
    }
  }
  .classList {
    // margin-left: 60px;
    .classItem {
      width: 380px;
      height: 206px;
      margin-left: 40px;
      margin-bottom: 60px;
      .itemTop {
        // height: 58px;
        border-radius: 10px 10px 0px 0px;
        background: #61a0ff;
        color: #fff;
        font-size: 20px;
        line-height: 50px;
      }
      .class_title {
        margin-left: 20px;
      }
      .topIcon {
        margin-right: 20px;
        line-height: 50px;
        text-align: right;
        cursor: pointer;
      }
      .itemMainBox {
        border: 1px solid #61a0ff;
        border-radius: 0px 0px 10px 10px;
        background: #fff;
        border-top: 0px;
        margin: 0 auto;
        padding: 26px 20px;
      }
      .itemMain {
        height: 90px;
        padding-top: 20px;
        background: #f2f7ff;
        border-radius: 10px 10px 10px 10px;
        cursor: pointer;
      }
      .mainImg {
        width: 52px;
        height: 39px;
        margin-left: 68px;
      }
      .mainNumBox {
      }
      .mainNumTitle {
        font-size: 20px;
      }
      .mainNum {
        font-size: 22px;
        color: #0066ff;
      }
    }
  }
}
</style>
